export const RoutePath = {
  LOGIN: '/',
  DASHBOARD: '/dashboard',
  INSIGHT: '/Insight',
  OEI: '/oei',
  ROSI: '/rosi',
  RISK: '/risk',
  INSIGHTDETAIL: '/InsightDetail',
  RISKIMPACT: '/riskimpact',
  PERSPECTIVEGRID: '/PerspectiveGrid',
  ADDEDITPERSPECTIVE: '/AddEditPerspective',
  PERSPECTIVEDETAIL: '/PerspectiveDetail',
  SECURITYPULSEGRID: '/SecurityPulseGrid',
  ADDEDITSECURITYPULSE: '/AddEditSecurityPulse',
  SECURITYPULSEDETAIL: '/SecurityPulseDetail',
  EDITPROFILE: '/EditProfile',
  RESETPASSWORD: '/ResetPassword',
  CONTACT: '/Contact',
  SENTMAIL: '/Sent_Mail',
  FORGETPASSWORD: '/Forget_Password/:token',
  ENTITYONBOARDING: '/EntityOnBoarding',
  RISKONBOARDING: '/RiskOnboarding',
  RISKDASHBOARD: '/RiskDashboard',
  VERIFYOTP: '/Verifyotp',
  DYNAMICSECURITYPULSEDETAILS: '/Share/SecurityPulseDetail/:id',
  CYBLEFEED: '/CybleFeed',
  ADVISORY: '/Advisory',
  DYNAMICADVISORYDETAILS: '/Share/Advisory/:id',
  COSTOFSECURITYCONTROLS: '/CostOfSecurityControls',
  AUTOMATICASSETDISCOVERY: '/AutomaticAssetDiscovery',
  AUTOMATICASSETDISCOVERYEVENTDETAIL: '/AutomaticAssetDiscoveryEventDetail',
  THREATINTEL: '/ThreatIntel',
  VULNERABILITY: '/vulnerability',
  RISKQUESTIONPOC: '/riskquestionpoc',

  // ADMIN ROUTE PATH CONSTANT
  ADMIN: '/admin',
  MANAGEUSERS: '/ManageUsers',
  USERMANAGEMENT: '/UserManagement',
  ADDPREDEFFUNCTIONS: '/AddPreDefFunctions',
  TENANTPACKAGES: '/TenantPackages',
  MANAGECATEGORY: '/ManageCategory',
  MANAGETYPESTAGS: '/ManageTypesTags',
  TMFFACTOR: '/TmfFactor',
  USECASEANDRULE: '/UseCaseAndRules',
  MODELPARAMETERS: '/ModelParameters',
  SECURITYQUESTIONS: '/SecurityQuestions',
};
