import {
  AnyAction,
  createSlice,
  Dispatch,
  PayloadAction,
} from '@reduxjs/toolkit';
import { TempFileInitialState } from './TempFileType';

const initialState: TempFileInitialState = {
  tempFile: undefined,
  tempFileName: undefined,
};

export const TempFileStore = createSlice({
  name: 'TempFileStore',
  initialState,
  reducers: {
    setFile: (
      state: TempFileInitialState,
      action: PayloadAction<TempFileInitialState>
    ) => {
      return { ...state, ...action.payload };
    },
  },
});

export const { setFile } = TempFileStore.actions;
export default TempFileStore.reducer;

const uploadTempFile =
  (value: TempFileInitialState) => async (dispatch: Dispatch<AnyAction>) => {
    dispatch(setFile(value));
  };

export const TempFileStoreActionCreator = {
  uploadTempFile,
};
