import { useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';

interface IuseGoogleAnalytics {
  additionalUserInfo: {
    userEmail: string;
    firstName: string;
    lastName: string;
    package: string;
    company: string;
  };
}

const useGoogleAnalytics = ({ additionalUserInfo }: IuseGoogleAnalytics) => {
  const location = useLocation();
  const history = useHistory();

  const mergedAdditionalUserInfo = useMemo(
    () => ({
      ...additionalUserInfo,
    }),
    [additionalUserInfo]
  );

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page_path: location.pathname,
      dimension1: mergedAdditionalUserInfo.userEmail,
      dimension2: mergedAdditionalUserInfo.firstName,
      dimension3: mergedAdditionalUserInfo.lastName,
      dimension4: mergedAdditionalUserInfo.company,
      dimension5: mergedAdditionalUserInfo.package,
      ...mergedAdditionalUserInfo,
    });

    // Clean up function
    return () => {
      // Track when component unmounts (optional)
      ReactGA.send({
        hitType: 'event',
        event_category: 'Component',
        event_action: 'Unmount',
        event_label: 'Component Unmounted',
        dimension1: mergedAdditionalUserInfo.userEmail,
        dimension2: mergedAdditionalUserInfo.firstName,
        dimension3: mergedAdditionalUserInfo.lastName,
        dimension4: mergedAdditionalUserInfo.company,
        dimension5: mergedAdditionalUserInfo.package,
        ...mergedAdditionalUserInfo,
      });
    };
  }, [location.pathname, mergedAdditionalUserInfo]);

  // Track page view on route change
  useEffect(() => {
    const unlisten = history.listen((location) => {
      ReactGA.set({ page_path: location.pathname });
      ReactGA.send({
        hitType: 'pageview',
        page_path: location.pathname,
        dimension1: mergedAdditionalUserInfo.userEmail,
        dimension2: mergedAdditionalUserInfo.firstName,
        dimension3: mergedAdditionalUserInfo.lastName,
        dimension4: mergedAdditionalUserInfo.company,
        dimension5: mergedAdditionalUserInfo.package,
        ...mergedAdditionalUserInfo,
      });
    });
    return () => unlisten();
  }, [history, mergedAdditionalUserInfo, additionalUserInfo]);
};

export default useGoogleAnalytics;
