import {
  AnyAction,
  createAsyncThunk,
  createSlice,
  Dispatch,
} from '@reduxjs/toolkit';
import { RootState } from '../../configureStore';

import { EndPoints } from '../../helpers/ApiEndPoints';
import { axiosPrivate } from '../../helpers/ApiClient';
import {
  InitialStateSecurityQuestion,
  isoQuestion,
  nistQuestion,
  pciQuestion,
} from './SecurityQuestionType';

const {
  GET_PCI_QUESTIONS,
  UPDATE_PCI_QUESTIONS,
  GET_ISO_QUESTIONS,
  UPDATE_ISO_QUESTIONS,
  GET_NIST_QUESTIONS,
  UPDATE_NIST_QUESTIONS,
} = EndPoints;

const initialState: InitialStateSecurityQuestion = {
  isLoading: false,
  pciMainSectionArr: [],
  pciQuestionArr: [],
  pciSubSectionArr: [],
  isoMainSectionArr: [],
  isoQuestionArr: [],
  isoSubSectionArr: [],
  nistCategorySectionArr: [],
  nistFunctionSectionArr: [],
  nistQuestionArr: [],
  nistSubCategorySectionArr: [],
};

const getPCIMainSections = createAsyncThunk<any, any>(
  'getPCIMainSections',
  async (payload) => {
    const resp = await axiosPrivate.post(GET_PCI_QUESTIONS, payload);
    return resp.data;
  }
);

const getPCISubSections = createAsyncThunk<any, any>(
  'getPCISubSections',
  async (payload) => {
    const resp = await axiosPrivate.post(GET_PCI_QUESTIONS, payload);
    return resp.data;
  }
);

const getPCIQuestions = createAsyncThunk<any, any>(
  'getPCIQuestions',
  async (payload) => {
    const resp = await axiosPrivate.post(GET_PCI_QUESTIONS, payload);
    return resp.data;
  }
);

const updateQuestionsData = createAsyncThunk<any, any>(
  'updateQuestionsData',
  async (payload) => {
    const resp = await axiosPrivate.post(UPDATE_PCI_QUESTIONS, payload);
    return resp.data;
  }
);

const updatePCIQuestionLocally =
  (payload: pciQuestion[]) =>
  async (dispatch: Dispatch<AnyAction>, getState: () => RootState) => {
    dispatch(updateQuestionArrLocal(payload));
  };

const getISOMainSections = createAsyncThunk<any, any>(
  'getISOMainSections',
  async (payload) => {
    const resp = await axiosPrivate.post(GET_ISO_QUESTIONS, payload);
    return resp.data;
  }
);

const getISOSubSections = createAsyncThunk<any, any>(
  'getISOSubSections',
  async (payload) => {
    const resp = await axiosPrivate.post(GET_ISO_QUESTIONS, payload);
    return resp.data;
  }
);

const getISOQuestions = createAsyncThunk<any, any>(
  'getISOQuestions',
  async (payload) => {
    const resp = await axiosPrivate.post(GET_ISO_QUESTIONS, payload);
    return resp.data;
  }
);

const updateISOQuestionsData = createAsyncThunk<any, any>(
  'updateISOQuestionsData',
  async (payload) => {
    const resp = await axiosPrivate.post(UPDATE_ISO_QUESTIONS, payload);
    return resp.data;
  }
);

const updateISOQuestionLocally =
  (payload: isoQuestion[]) =>
  async (dispatch: Dispatch<AnyAction>, getState: () => RootState) => {
    dispatch(updateISOQuestionArrLocal(payload));
  };

const getNISTFunctionSection = createAsyncThunk<any, any>(
  'getNISTFunctionSection',
  async (payload) => {
    const resp = await axiosPrivate.post(GET_NIST_QUESTIONS, payload);
    return resp.data;
  }
);

const getNISTCategorySection = createAsyncThunk<any, any>(
  'getNISTCategorySection',
  async (payload) => {
    const resp = await axiosPrivate.post(GET_NIST_QUESTIONS, payload);
    return resp.data;
  }
);

const getNISTSubCategorySection = createAsyncThunk<any, any>(
  'getNISTSubCategorySection',
  async (payload) => {
    const resp = await axiosPrivate.post(GET_NIST_QUESTIONS, payload);
    return resp.data;
  }
);

const getNISTQuestionArr = createAsyncThunk<any, any>(
  'getNISTQuestionArr',
  async (payload) => {
    const resp = await axiosPrivate.post(GET_NIST_QUESTIONS, payload);
    return resp.data;
  }
);

const updateNISTQuestionsData = createAsyncThunk<any, any>(
  'updateNISTQuestionsData',
  async (payload) => {
    const resp = await axiosPrivate.post(UPDATE_NIST_QUESTIONS, payload);
    return resp.data;
  }
);

const updateNISTQuestionLocally =
  (payload: nistQuestion[]) =>
  async (dispatch: Dispatch<AnyAction>, getState: () => RootState) => {
    dispatch(updateNISTQuestionArrLocal(payload));
  };

export const SecurityQuestionsSlice = createSlice({
  name: 'SecurityQuestionsSlice',
  initialState,
  reducers: {
    resetState: (state: InitialStateSecurityQuestion) => {
      return {
        ...initialState,
      };
    },
    updateQuestionArrLocal: (
      state,
      action: {
        payload: pciQuestion[];
        type: string;
      }
    ) => {
      return {
        ...state,
        pciQuestionArr: action.payload,
      };
    },
    updateISOQuestionArrLocal: (
      state,
      action: {
        payload: isoQuestion[];
        type: string;
      }
    ) => {
      return {
        ...state,
        isoQuestionArr: action.payload,
      };
    },
    updateNISTQuestionArrLocal: (
      state,
      action: {
        payload: nistQuestion[];
        type: string;
      }
    ) => {
      return {
        ...state,
        nistQuestionArr: action.payload,
      };
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getPCIMainSections.pending, (state, action) => {
        // Update state with fetched data on success
        state.isLoading = true;
      })
      .addCase(getPCIMainSections.fulfilled, (state, action) => {
        // Update state with fetched data on success
        state.isLoading = false;
        state.pciMainSectionArr = action.payload.data;
      })
      .addCase(getPCIMainSections.rejected, (state, action) => {
        // Update state with fetched data on success
        state.isLoading = false;
      })
      .addCase(getPCISubSections.fulfilled, (state, action) => {
        // Update state with fetched data on success
        state.pciSubSectionArr = action.payload.data;
      })
      .addCase(getPCIQuestions.fulfilled, (state, action) => {
        // Update state with fetched data on success
        state.pciQuestionArr = action.payload.data;
      })
      .addCase(updateQuestionsData.fulfilled, (state, action) => {
        // Update state with fetched data on success
        state.pciQuestionArr = action.payload.data;
      })
      .addCase(getISOMainSections.pending, (state, action) => {
        // Update state with fetched data on success
        state.isLoading = true;
      })
      .addCase(getISOMainSections.fulfilled, (state, action) => {
        // Update state with fetched data on success
        state.isLoading = false;
        state.isoMainSectionArr = action.payload.data;
      })
      .addCase(getISOMainSections.rejected, (state, action) => {
        // Update state with fetched data on success
        state.isLoading = false;
      })
      .addCase(getISOSubSections.fulfilled, (state, action) => {
        // Update state with fetched data on success
        state.isoSubSectionArr = action.payload.data;
      })
      .addCase(getISOQuestions.fulfilled, (state, action) => {
        // Update state with fetched data on success
        state.isoQuestionArr = action.payload.data;
      })
      .addCase(updateISOQuestionsData.fulfilled, (state, action) => {
        // Update state with fetched data on success
        state.isoQuestionArr = action.payload.data;
      })
      .addCase(getNISTFunctionSection.pending, (state, action) => {
        // Update state with fetched data on success
        state.isLoading = true;
      })
      .addCase(getNISTFunctionSection.fulfilled, (state, action) => {
        // Update state with fetched data on success
        state.isLoading = false;
        state.nistFunctionSectionArr = action.payload.data;
      })
      .addCase(getNISTFunctionSection.rejected, (state, action) => {
        // Update state with fetched data on success
        state.isLoading = false;
      })
      .addCase(getNISTCategorySection.fulfilled, (state, action) => {
        // Update state with fetched data on success
        state.nistCategorySectionArr = action.payload.data;
      })
      .addCase(getNISTSubCategorySection.fulfilled, (state, action) => {
        // Update state with fetched data on success
        state.nistSubCategorySectionArr = action.payload.data;
      })
      .addCase(getNISTQuestionArr.fulfilled, (state, action) => {
        // Update state with fetched data on success
        state.nistQuestionArr = action.payload.data;
      })
      .addCase(updateNISTQuestionsData.fulfilled, (state, action) => {
        // Update state with fetched data on success
        state.nistQuestionArr = action.payload.data;
      });
  },
});

const ResetState =
  () => async (dispatch: Dispatch<AnyAction>, getState: () => RootState) => {
    dispatch(resetState());
  };

const {
  // Add action creators here
  resetState,
  updateQuestionArrLocal,
  updateISOQuestionArrLocal,
  updateNISTQuestionArrLocal,
} = SecurityQuestionsSlice.actions;

export default SecurityQuestionsSlice.reducer;

export const SecurityQuestionsActionCreator = {
  ResetState,
  getPCIMainSections,
  getPCISubSections,
  getPCIQuestions,
  updatePCIQuestionLocally,
  updateQuestionsData,
  updateISOQuestionLocally,
  getISOMainSections,
  getISOSubSections,
  getISOQuestions,
  updateISOQuestionsData,
  getNISTFunctionSection,
  getNISTCategorySection,
  getNISTSubCategorySection,
  getNISTQuestionArr,
  updateNISTQuestionsData,
  updateNISTQuestionLocally,
};
