import {
  AnyAction,
  createAsyncThunk,
  createSlice,
  Dispatch,
} from '@reduxjs/toolkit';
import {
  cloudFactorAndWeights,
  complianceStandardWeight,
  InitialStateModelParameters,
  manageComplianceClientAdmin,
  managePriorityClientAdmin,
  occsImpact,
} from './AdminType';
import { axiosPrivate } from '../../helpers/ApiClient';
import { EndPoints } from '../../helpers/ApiEndPoints';
import { RootState } from '../../configureStore';

const {
  GET_OCCS_IMPACT,
  ADD_UPDATE_OCCS_IMPACT,
  GET_COMPLIANCE_STANDARDS_WEIGHTS,
  ADD_UPDATE_COMPLIANCE_STANDARDS_WEIGHTS,
  UPDATE_STANDARDS_PRIORITY,
  GET_CLOUD_FACTOR_WEIGHTS,
  ADD_UPDATE_CLOUD_FACTOR_WEIGHTS,
  GET_PREDEFINED_PRIORITY,
  UPDATE_CLIENT_ADMIN_PRIORITY,
  GET_MANAGE_COMPLIANCE,
  UPDATE_MANAGE_COMPLIANCE,
} = EndPoints;

const initialState: InitialStateModelParameters = {
  isOccsLoading: false,
  occsImpactData: [],
  complianceStandardWeightsData: [],
  cloudFactorAndWeightsData: [],
  managePriority: {
    clientPriority: [],
    predefinedPriority: [],
  },
  manageCompliance: [],
  overall_compliance_security_score: null,
  modalParameterStatus: {
    isSourceFound: true,
    error: '',
  },
};

const getOCCSImpact = createAsyncThunk<any, any>('getOCCSImpact', async () => {
  const respFeeds = await axiosPrivate.post(GET_OCCS_IMPACT, {});
  return respFeeds;
});
const getCSW = createAsyncThunk<any, any>('getCSW', async () => {
  const respFeeds = await axiosPrivate.post(
    GET_COMPLIANCE_STANDARDS_WEIGHTS,
    {}
  );
  return respFeeds;
});
const getCloudFactorWeights = createAsyncThunk<any, any>(
  'getCloudFactorWeights',
  async () => {
    const respFeeds = await axiosPrivate.post(GET_CLOUD_FACTOR_WEIGHTS, {});
    return respFeeds;
  }
);
const getPredefinedPriority = createAsyncThunk<any, any>(
  'getPredefinedPriority',
  async () => {
    const respFeeds = await axiosPrivate.post(GET_PREDEFINED_PRIORITY, {});
    return respFeeds;
  }
);
const getComplianceData = createAsyncThunk<any, any>(
  'getComplianceData',
  async () => {
    const respFeeds = await axiosPrivate.post(GET_MANAGE_COMPLIANCE, {});
    return respFeeds;
  }
);

const addUpdateOCCSImpact = createAsyncThunk<any, occsImpact>(
  'addUpdateOCCSImpact',
  async (payload) => {
    const respFeeds = await axiosPrivate.post(ADD_UPDATE_OCCS_IMPACT, payload);
    return respFeeds;
  }
);

const addUpdateCSW = createAsyncThunk<any, complianceStandardWeight>(
  'addUpdateCSW',
  async (payload) => {
    const respFeeds = await axiosPrivate.post(
      ADD_UPDATE_COMPLIANCE_STANDARDS_WEIGHTS,
      payload
    );
    return respFeeds;
  }
);
const addUpdateCloudFactor = createAsyncThunk<any, cloudFactorAndWeights>(
  'addUpdateCloudFactor',
  async (payload) => {
    const respFeeds = await axiosPrivate.post(
      ADD_UPDATE_CLOUD_FACTOR_WEIGHTS,
      payload
    );
    return respFeeds;
  }
);
const addUpdateCSWPriority = createAsyncThunk<any, complianceStandardWeight[]>(
  'addUpdateCSWPriority',
  async (payload) => {
    const respFeeds = await axiosPrivate.post(
      UPDATE_STANDARDS_PRIORITY,
      payload
    );
    return respFeeds;
  }
);

const addUpdateClientPriority = createAsyncThunk<
  any,
  managePriorityClientAdmin[]
>('addUpdateClientPriority', async (payload) => {
  const respFeeds = await axiosPrivate.post(UPDATE_CLIENT_ADMIN_PRIORITY, {
    data: payload,
  });
  return respFeeds;
});

const addUpdateComplianceScore = createAsyncThunk<
  any,
  manageComplianceClientAdmin[]
>('addUpdateComplianceScore', async (payload) => {
  const respFeeds = await axiosPrivate.post(UPDATE_MANAGE_COMPLIANCE, {
    data: payload,
  });
  return respFeeds;
});

const updateManagePriorityLocal =
  (payload: {
    predefinedPriority: managePriorityClientAdmin[];
    clientPriority: managePriorityClientAdmin[];
  }) =>
  async (dispatch: Dispatch<AnyAction>, getState: () => RootState) => {
    dispatch(updatePriority(payload));
  };
const updateComplianceStandardWeightsData =
  (payload: complianceStandardWeight[]) =>
  async (dispatch: Dispatch<AnyAction>, getState: () => RootState) => {
    dispatch(updateComplianceStandardWeights(payload));
  };

const updateComplianceData =
  (payload: manageComplianceClientAdmin[]) =>
  async (dispatch: Dispatch<AnyAction>, getState: () => RootState) => {
    dispatch(updateComplianceScore(payload));
  };

export const ModelParameterStore = createSlice({
  name: 'ModelParameterStore',
  initialState,
  reducers: {
    resetState: () => {
      return {
        ...initialState,
      };
    },
    updatePriority: (
      state,
      action: {
        payload: {
          predefinedPriority: managePriorityClientAdmin[];
          clientPriority: managePriorityClientAdmin[];
        };
        type: string;
      }
    ) => {
      return {
        ...state,
        managePriority: action.payload,
      };
    },
    updateComplianceStandardWeights: (
      state,
      action: {
        payload: complianceStandardWeight[];
        type: string;
      }
    ) => {
      return {
        ...state,
        complianceStandardWeightsData: action.payload,
      };
    },
    updateComplianceScore: (
      state,
      action: {
        payload: manageComplianceClientAdmin[];
        type: string;
      }
    ) => {
      return {
        ...state,
        manageCompliance: action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getOCCSImpact.pending, (state) => {
        state.isOccsLoading = true;
      })
      .addCase(getOCCSImpact.fulfilled, (state, action) => {
        state.isOccsLoading = false;
        state.occsImpactData = action.payload.data.data;
      })
      .addCase(getOCCSImpact.rejected, (state) => {
        state.isOccsLoading = false;
      })
      .addCase(getCSW.pending, (state) => {
        state.isOccsLoading = true;
      })
      .addCase(getCSW.fulfilled, (state, action) => {
        state.isOccsLoading = false;
        state.complianceStandardWeightsData = action.payload.data.data;
      })
      .addCase(getCSW.rejected, (state) => {
        state.isOccsLoading = false;
      })
      .addCase(getCloudFactorWeights.pending, (state) => {
        state.isOccsLoading = true;
      })
      .addCase(getCloudFactorWeights.fulfilled, (state, action) => {
        state.isOccsLoading = false;
        state.cloudFactorAndWeightsData = action.payload.data.data;
      })
      .addCase(getCloudFactorWeights.rejected, (state) => {
        state.isOccsLoading = false;
      })
      .addCase(addUpdateOCCSImpact.pending, (state) => {
        state.isOccsLoading = true;
      })
      .addCase(addUpdateOCCSImpact.fulfilled, (state, action) => {
        state.isOccsLoading = false;
        state.occsImpactData = action.payload.data.data;
      })
      .addCase(addUpdateOCCSImpact.rejected, (state) => {
        state.isOccsLoading = false;
      })
      .addCase(addUpdateCSW.pending, (state) => {
        state.isOccsLoading = true;
      })
      .addCase(addUpdateCSW.fulfilled, (state, action) => {
        state.isOccsLoading = false;
        state.complianceStandardWeightsData = action.payload.data.data;
      })
      .addCase(addUpdateCSW.rejected, (state) => {
        state.isOccsLoading = false;
      })
      .addCase(addUpdateCloudFactor.pending, (state) => {
        state.isOccsLoading = true;
      })
      .addCase(addUpdateCloudFactor.fulfilled, (state, action) => {
        state.isOccsLoading = false;
        state.cloudFactorAndWeightsData = action.payload.data.data;
      })
      .addCase(addUpdateCloudFactor.rejected, (state) => {
        state.isOccsLoading = false;
      })
      .addCase(addUpdateCSWPriority.pending, (state) => {
        state.isOccsLoading = true;
      })
      .addCase(addUpdateCSWPriority.fulfilled, (state, action) => {
        state.isOccsLoading = false;
        state.complianceStandardWeightsData = action.payload.data.data;
      })
      .addCase(addUpdateCSWPriority.rejected, (state) => {
        state.isOccsLoading = false;
      })
      .addCase(getPredefinedPriority.pending, (state) => {
        state.isOccsLoading = true;
      })
      .addCase(getPredefinedPriority.fulfilled, (state, action) => {
        state.isOccsLoading = false;

        if (action.payload.data.status !== 200) {
          state.modalParameterStatus = {
            isSourceFound: false,
            error: action.payload.data.message,
          };
          return;
        }
        state.modalParameterStatus = {
          isSourceFound: true,
          error: '',
        };
        state.managePriority = action.payload.data.data;
      })
      .addCase(getPredefinedPriority.rejected, (state) => {
        state.isOccsLoading = false;
      })
      .addCase(addUpdateClientPriority.pending, (state) => {
        state.isOccsLoading = true;
      })
      .addCase(addUpdateClientPriority.fulfilled, (state, action) => {
        state.isOccsLoading = false;
        state.managePriority = action.payload.data.data;
      })
      .addCase(addUpdateClientPriority.rejected, (state) => {
        state.isOccsLoading = false;
      })
      .addCase(getComplianceData.pending, (state) => {
        state.isOccsLoading = true;
      })
      .addCase(getComplianceData.fulfilled, (state, action) => {
        state.isOccsLoading = false;
        state.manageCompliance = action.payload.data.data;
        state.overall_compliance_security_score =
          action.payload.data.overall_compliance_security_score;
      })
      .addCase(getComplianceData.rejected, (state) => {
        state.isOccsLoading = false;
      })
      .addCase(addUpdateComplianceScore.pending, (state) => {
        state.isOccsLoading = true;
      })
      .addCase(addUpdateComplianceScore.fulfilled, (state, action) => {
        state.isOccsLoading = false;
        state.manageCompliance = action.payload.data.data;
        state.overall_compliance_security_score =
          action.payload.data.overall_compliance_security_score;
      })
      .addCase(addUpdateComplianceScore.rejected, (state) => {
        state.isOccsLoading = false;
      });
  },
});

export const {
  resetState,
  updatePriority,
  updateComplianceStandardWeights,
  updateComplianceScore,
} = ModelParameterStore.actions;
export default ModelParameterStore.reducer;

const ResetState = () => async (dispatch: Dispatch<AnyAction>) => {
  dispatch(resetState());
};

export const ModelParameterStoreActionCreator = {
  ResetState,
  updateComplianceStandardWeightsData,
  getOCCSImpact,
  addUpdateOCCSImpact,
  getCSW,
  addUpdateCSW,
  addUpdateCSWPriority,
  getCloudFactorWeights,
  addUpdateCloudFactor,
  getPredefinedPriority,
  updateManagePriorityLocal,
  addUpdateClientPriority,
  getComplianceData,
  updateComplianceData,
  addUpdateComplianceScore,
};
