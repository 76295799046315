import darkLogo from '../../assets/images/darkLogo.png';
import lightLogo from '../../assets/images/lightLogo.png';
import StyledLogo from './BrandingStyle';

interface Props {
  theme: 'light' | 'dark' | string;
}

function Branding({ theme = 'light' }: Props) {
  return (
    <StyledLogo>
      <div className='brandPoweredBy'>Powered by</div>
      <img
        className='brandNetrumLogo'
        src={theme === 'dark' ? lightLogo : darkLogo}
        alt='SITA - Netrum'
      />
    </StyledLogo>
  );
}

export default Branding;
