import {
  AnyAction,
  createAsyncThunk,
  createSlice,
  Dispatch,
} from '@reduxjs/toolkit';
import { RootState } from '../../configureStore';

import { EndPoints } from '../../helpers/ApiEndPoints';
import { axiosPrivate, axiosPrivateFileUpload } from '../../helpers/ApiClient';
import { InitialStateVulnerability } from './VulnerabilityType';
import { toast } from 'react-toastify';

const {
  VULNERABILITIES_GRID,
  UPLOAD_VULNERABILITIES_CSV,
  MASTER_VULNERABILITY_GRID,
  UPDATE_VULNERABILITY_MASTER,
} = EndPoints;

const initialState: InitialStateVulnerability = {
  isLoading: false,
  gridHeader: [],
  gridData: [],
  csvResponse: {},
};

const fetchVulnerabilityGridData = createAsyncThunk<
  any,
  { for: 'assets' | 'master' }
>('fetchVulnerabilityGridData', async (payload) => {
  if (payload.for === 'assets') {
    const resp = await axiosPrivate.post(VULNERABILITIES_GRID, {});
    return resp.data;
  } else if (payload.for === 'master') {
    const resp = await axiosPrivate.post(MASTER_VULNERABILITY_GRID, {});
    return resp.data;
  }
});
const updateVulnerabilityGridMasterData = createAsyncThunk<any, any>(
  'updateVulnerabilityGridMasterData',
  async (payload) => {
    const resp = await axiosPrivate.post(UPDATE_VULNERABILITY_MASTER, payload);
    toast(resp.data.message, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      progress: undefined,
      theme: 'dark',
    });
    return resp.data;
  }
);

const uploadVulnerabilitySheet = createAsyncThunk(
  'uploadVulnerabilitySheet',
  async (payload: any) => {
    var data = new FormData();
    data.append('File', payload);
    const resp = await axiosPrivateFileUpload.post(
      UPLOAD_VULNERABILITIES_CSV,
      data
    );

    toast(resp.data.message, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      progress: undefined,
      theme: 'dark',
    });

    return resp.data.data;
  }
);

export const VulnerabilityPageSlice = createSlice({
  name: 'VulnerabilityPageSlice',
  initialState,
  reducers: {
    resetState: (state: InitialStateVulnerability) => {
      return {
        ...initialState,
      };
    },
  },

  extraReducers: (builder) => {
    builder.addCase(fetchVulnerabilityGridData.pending, (state, action) => {
      // Update state with fetched data on success
      state.isLoading = true;
    });
    builder.addCase(fetchVulnerabilityGridData.fulfilled, (state, action) => {
      // Update state with fetched data on success
      state.gridHeader = action.payload.gridHeader;
      state.gridData = action.payload.gridData;
      state.isLoading = false;
    });
    builder.addCase(fetchVulnerabilityGridData.rejected, (state, action) => {
      // Update state with fetched data on success
      state.isLoading = false;
    });
    builder.addCase(uploadVulnerabilitySheet.fulfilled, (state, action) => {
      // Update state with fetched data on success
      state.csvResponse = action.payload;
    });
  },
});

const ResetState =
  () => async (dispatch: Dispatch<AnyAction>, getState: () => RootState) => {
    dispatch(resetState());
  };

const {
  // Add action creators here
  resetState,
} = VulnerabilityPageSlice.actions;

export default VulnerabilityPageSlice.reducer;

export const VulnerabilityPageActionCreator = {
  ResetState,
  fetchVulnerabilityGridData,
  uploadVulnerabilitySheet,
  updateVulnerabilityGridMasterData,
};
